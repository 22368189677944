<template>
  <InfoWraper>
    <!-- <Message /> -->
    <Notification />
    <!-- <Settings /> -->
    <!-- <Support /> -->
    <!-- <div class="nav-author"> -->
    <!--   <sdDropdown placement="bottomRight" :action="['click']"> -->
    <!--     <template v-slot:overlay> -->
    <!--       <NavAuth> -->
    <!--         <router-link @click="() => onFlagChangeHandle('english')" to="#"> -->
    <!--           <img :src="require('../../../static/img/flag/english.png')" alt="" /> -->
    <!--           <span>English</span> -->
    <!--         </router-link> -->
    <!--         <router-link @click="() => onFlagChangeHandle('germany')" to="#"> -->
    <!--           <img :src="require('../../../static/img/flag/germany.png')" alt="" /> -->
    <!--           <span>Germany</span> -->
    <!--         </router-link> -->
    <!--         <router-link @click="() => onFlagChangeHandle('spain')" to="#"> -->
    <!--           <img :src="require('../../../static/img/flag/spain.png')" alt="" /> -->
    <!--           <span>Spain</span> -->
    <!--         </router-link> -->
    <!--         <router-link @click="() => onFlagChangeHandle('turky')" to="#"> -->
    <!--           <img :src="require('../../../static/img/flag/turky.png')" alt="" /> -->
    <!--           <span>Turky</span> -->
    <!--         </router-link> -->
    <!--       </NavAuth> -->
    <!--     </template> -->
    <!--     <a to="#" class="head-example"> -->
    <!--       <img :src="require(`../../../static/img/flag/${flag}.png`)" alt="" /> -->
    <!--     </a> -->
    <!--   </sdDropdown> -->
    <!-- </div> -->

    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info">
                <img :src="require('../../../static/img/avatar/NoPhoto.png')" alt="" />
                <figcaption>
                  <sdHeading as="h5">{{ profile.name }}</sdHeading>
                  <p v-if="profile.divisi">{{ profile.divisi.name }}</p>
                  <p>{{ profile.roles[0].name }}</p>
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a @click="showChangePass"> <sdFeatherIcons type="lock" /> Ubah Password </a>
                </li>
              </ul>
              <!-- <ul class="user-dropdwon__links"> -->
              <!--   <li> -->
              <!--     <a to="#"> <sdFeatherIcons type="user" /> Profile </a> -->
              <!--   </li> -->
              <!--   <li> -->
              <!--     <a to="#"> <sdFeatherIcons type="settings" /> Settings </a> -->
              <!--   </li> -->
              <!--   <li> -->
              <!--     <a to="#"> <sdFeatherIcons type="dollar-sign" /> Billing </a> -->
              <!--   </li> -->
              <!--   <li> -->
              <!--     <a to="#"> <sdFeatherIcons type="users" /> Activity </a> -->
              <!--   </li> -->
              <!--   <li> -->
              <!--     <a to="#"> <sdFeatherIcons type="bell" /> Help </a> -->
              <!--   </li> -->
              <!-- </ul> -->
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#"> <LogoutOutlined /> Sign Out </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar :src="require('../../../static/img/avatar/NoPhoto.png')" />
        </a>
      </sdPopover>
    </div>

    <a-modal :visible="changePass.visible" :title="changePass.title" :width="600" :onCancel="handleCancelChangePass">
      <a-form
        layout="horizontal"
        :model="formModalState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        :rules="modalRules"
        id="changePassForm"
        @finish="doChangePass"
      >
        <a-form-item name="newPass" label="Password Baru">
          <a-input-password v-model:value="formModalState.newPass" placeholder="Masukkan Password Baru" />
        </a-form-item>
        <a-form-item name="newPass2" label="Ulangi Password Baru">
          <a-input-password v-model:value="formModalState.newPass2" placeholder="Ulangi Password Baru" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="back" @click="handleCancelChangePass">Batal</a-button>
        <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="changePassForm">
          {{ formModalState.isLoading ? 'Harap Menunggu...' : 'Ubah' }}
        </a-button>
      </template>
    </a-modal>
  </InfoWraper>
</template>

<script>
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Support from './Support';
// import Settings from './Settings';
import Notification from './Notification';
// import Message from './Message';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { getItem } from '@/utility/localStorageControl';
import { reactive } from 'vue';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    // NavAuth,
    UserDropDwon,
    // Support,
    // Settings,
    Notification,
    // Message,
    LogoutOutlined,
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const { dispatch } = useStore();
    const { push } = useRouter();
    const SignOut = e => {
      e.preventDefault();
      push('/auth/login');
      dispatch('logOut');
    };
    const profile = getItem('profile_plnbb');
    const changePass = reactive({
      visible: false,
      title: null,
    });
    const showChangePass = () => {
      changePass.title = 'Ubah Password';

      changePass.visible = true;
    };
    const handleCancelChangePass = () => {
      changePass.visible = false;
    };
    const formModalState = reactive({
      isLoading: false,
      newPass: null,
      newPass2: null,
    });
    const labelCol = {
      lg: 9,
      md: 9,
      xs: 24,
    };
    const wrapperCol = {
      lg: 15,
      md: 15,
      xs: 24,
    };
    const modalRules = {
      // newPass: [{ required: true, message: 'Password Baru harus diisi' }],
      newPass: [
        { required: true, message: 'Password Baru harus diisi' },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          message: 'Password harus minimal 8 karakter, dan harus kombinasi huruf kecil, huruf besar, angka, dan simbol',
        },
      ],
      newPass2: [
        { required: true, message: 'Ulangi Password Baru harus diisi' },
        {
          validator: (rule, value, callback) => {
            if (value !== formModalState.newPass) {
              callback('Password tidak sama');
            } else {
              callback();
            }
          },
        },
      ],
    };
    const doChangePass = () => {
      formModalState.isLoading = true;

      DataService.post('change-password', {
        password: formModalState.newPass,
      })
        .then(response => {
          changePass.visible = false;
          formModalState.isLoading = false;
          formModalState.newPass = null;
          formModalState.newPass2 = null;

          if (response.status === 200) {
            notification.success({
              message: response.data.message,
            });
          } else {
            notification.error({
              message: response.data.message,
            });
          }
        })
        .catch(error => {
          formModalState.isLoading = false;
          notification.error({
            message: error.response.data.message,
          });
        });
    };

    return {
      SignOut,
      profile,
      changePass,
      showChangePass,
      handleCancelChangePass,
      formModalState,
      labelCol,
      wrapperCol,
      modalRules,
      doChangePass,
    };
  },
  methods: {
    onFlagChangeHandle: function(value) {
      this.flag = value;
    },
  },
};
</script>
