<template>
  <TopMenuStyle>
    <div class="strikingDash-top-menu">
      <ul>
        <li class="has-subMenu" v-if="checkPermission(['view dashboard kpi korporat', 'view dashboard kpi divisi'])">
          <a href="#" class="parent">
            Dashboard
          </a>
          <ul class="subMenu">
            <li @click="addParentActive" v-if="checkPermission('view dashboard kpi korporat')">
              <router-link to="/dash/kpi-korporat">
                Indikator Kinerja
              </router-link>
            </li>
            <li class="has-subMenu-left" v-if="checkPermission('view dashboard kpi divisi')">
              <a href="#" class="parent">
                KPI
              </a>
              <ul class="subMenu">
                <!-- <li @click="addParentActive" v-for="divisi in menus.divisi" :key="divisi.value">
                  <router-link
                    :to="'/dash/kpi-divisi/' + divisi.value"
                    v-if="
                      checkPermission('view dashboard kpi divisi ' + divisi.value) || divisi.value == profile.divisi_id
                    "
                  >
                    {{ divisi.text }}
                  </router-link>
                </li> -->
                <li
                  @click="addParentActive"
                  v-for="divisi in menus.divisi"
                  :key="divisi.id"
                  :class="{ 'has-subMenu-left': divisi.divisis.length > 0 }"
                >
                  <router-link
                    :to="'/dash/kpi-divisi/' + divisi.id"
                    v-if="checkPermission('view dashboard kpi divisi ' + divisi.name) || divisi.id == profile.divisi_id"
                  >
                    {{ divisi.name }}
                  </router-link>
                  <a
                    href="#"
                    v-if="
                      profile.divisi &&
                        divisi.id == profile.divisi.parent_id &&
                        !checkPermission('view dashboard kpi divisi ' + divisi.name)
                    "
                  >
                    {{ divisi.name }}
                  </a>
                  <ul class="subMenu" v-if="divisi.divisis.length > 0">
                    <li @click="addParentActive" v-for="subDivisi in divisi.divisis" :key="subDivisi.id">
                      <router-link
                        :to="'/dash/kpi-divisi/' + subDivisi.id"
                        v-if="
                          checkPermission('view dashboard kpi divisi ' + subDivisi.name) ||
                            subDivisi.id == profile.divisi_id ||
                            subDivisi.parent_id == profile.divisi_id
                        "
                      >
                        {{ subDivisi.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/laporan-manajemen`"> -->
            <!--     Laporan Manajemen -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/permintaan-direksi`"> -->
            <!--     Permintaan Direksi -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/permintaan-eksternal`"> -->
            <!--     Permintaan External -->
            <!--   </router-link> -->
            <!-- </li> -->
          </ul>
        </li>

        <!-- <li class="has-subMenu"> -->
        <!--   <a href="#" class="parent"> -->
        <!--     Indikator Kinerja -->
        <!--   </a> -->
        <!--   <ul class="subMenu"> -->
        <!--     <li @click="addParentActive"> -->
        <!--       <router-link to="/kpi/kpi-korporat"> -->
        <!--         Indikator Kinerja -->
        <!--       </router-link> -->
        <!--     </li> -->
        <!--     <li v-for="kpiKorporat in menus.kpiKorporat" @click="addParentActive" :key="kpiKorporat.id"> -->
        <!--       <router-link :to="'/kpi/kpi-korporat-table/' + kpiKorporat.value"> -->
        <!--         {{ kpiKorporat.text }} -->
        <!--       </router-link> -->
        <!--     </li> -->
        <!--   </ul> -->
        <!-- </li> -->

        <li class="has-subMenu" v-if="checkPermission(['view kpi korporat', 'view kpi divisi'])">
          <a href="#" class="parent">
            KPI
          </a>
          <ul class="subMenu">
            <li @click="addParentActive" v-if="checkPermission('view kpi korporat')">
              <router-link :to="'/kpi/kpi-korporat/' + menus.month + '/' + menus.year">
                Indikator Kinerja
              </router-link>
            </li>

            <!-- <li v-for="kpiKorporat in menus.kpiKorporat" @click="addParentActive" :key="kpiKorporat.id"> -->
            <!--   <router-link :to="'/kpi/kpi-korporat-table/' + kpiKorporat.value"> -->
            <!--     {{ kpiKorporat.text }} -->
            <!--   </router-link> -->
            <!-- </li> -->
            <li class="has-subMenu-left" v-if="checkPermission('view kpi divisi')">
              <a class="parent"> KPI </a>
              <ul class="subMenu">
                <!-- <li @click="addParentActive" v-for="divisi in menus.divisi" :key="divisi.value">
                  <router-link
                    :to="'/kpi/kpi-divisi/' + divisi.value + '/' + menus.month + '/' + menus.year"
                    v-if="checkPermission('view kpi divisi ' + divisi.value) || divisi.value == profile.divisi_id"
                  >
                    {{ divisi.text }}
                  </router-link>
                </li> -->
                <li
                  @click="addParentActive"
                  v-for="divisi in menus.divisi"
                  :key="divisi.id"
                  :class="{ 'has-subMenu-left': divisi.divisis.length > 0 }"
                >
                  <router-link
                    :to="'/kpi/kpi-divisi/' + divisi.id + '/' + menus.month + '/' + menus.year"
                    v-if="checkPermission('view dashboard kpi divisi ' + divisi.name) || divisi.id == profile.divisi_id"
                  >
                    {{ divisi.name }}
                  </router-link>
                  <a
                    href="#"
                    v-if="
                      profile.divisi &&
                        divisi.id == profile.divisi.parent_id &&
                        !checkPermission('view dashboard kpi divisi ' + divisi.name)
                    "
                  >
                    {{ divisi.name }}
                  </a>
                  <ul class="subMenu" v-if="divisi.divisis.length > 0">
                    <li @click="addParentActive" v-for="subDivisi in divisi.divisis" :key="subDivisi.id">
                      <router-link
                        :to="'/kpi/kpi-divisi/' + subDivisi.id + '/' + menus.month + '/' + menus.year"
                        v-if="
                          checkPermission('view dashboard kpi divisi ' + subDivisi.name) ||
                            subDivisi.id == profile.divisi_id ||
                            subDivisi.parent_id == profile.divisi_id
                        "
                      >
                        {{ subDivisi.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/kpi/target-kpi-korporat`"> -->
            <!--     Target Indikator Kinerja -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/kpi/target-kpi-divisi`"> -->
            <!--     Target KPI -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/kpi/laporan-kpi-korporat`"> -->
            <!--     Realisasi Indikator Kinerja -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/kpi/laporan-kpi-divisi`"> -->
            <!--     Realisasi KPI -->
            <!--   </router-link> -->
            <!-- </li> -->
            <!-- <li @click="addParentActive"> -->
            <!--   <router-link :to="`/approval`"> -->
            <!--     Approval -->
            <!--   </router-link> -->
            <!-- </li> -->
          </ul>
        </li>

        <!-- <li class="has-subMenu" v-if="checkPermission('view laporan manajemen')">
          <a href="#" class="parent">
            Laporan Manajemen
          </a>
          <ul class="subMenu">
            <li @click="addParentActive" v-if="checkPermission('view laporan manajemen kebutuhan dan realisasi tk')">
              <router-link to="/laporan-manajemen/kebutuhan-tk-divisi-manajemen/index">
                Kebutuhan dan Realisasi TK
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view laporan manajemen sharing knowledge')">
              <router-link to="/laporan-manajemen/sharing-knowledge">
                Sharing Knowledge
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view laporan manajemen kegiatan csr')">
              <router-link to="/laporan-manajemen/kegiatan-crs">
                Kegiatan CSR
              </router-link>
            </li>
            <li @click="addParentActive">
              <router-link
                to="/laporan-manajemen/progress-rjpp"
                v-if="checkPermission('view laporan manajemen progress rjpp')"
              >
                Realisasi Kinerja IT (Progrees RJPP)
              </router-link>
            </li>
            <li class="has-subMenu-left" v-if="checkPermission('view laporan manajemen target realisasi')">
              <a href="#" class="parent">
                Laporan Target Realisasi
              </a>
              <ul class="subMenu">
                <li
                  @click="addParentActive"
                  v-for="laporan_manajemen_target_realisasi in menus.laporan_manajemen_target_realisasi"
                  :key="laporan_manajemen_target_realisasi.id"
                >
                  <router-link
                    :to="
                      '/laporan-manajemen/laporan-manajemen-target-realisasi/' +
                        laporan_manajemen_target_realisasi.value +
                        '/index'
                    "
                    v-if="checkPermission('view laporan manajemen ' + laporan_manajemen_target_realisasi.text)"
                  >
                    {{ laporan_manajemen_target_realisasi.text }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              @click="addParentActive"
              v-for="laporan_manajemen in menus.laporan_manajemen"
              :key="laporan_manajemen.id"
            >
              <router-link
                :to="'/laporan-manajemen/laporan-manajemen/' + laporan_manajemen.value"
                v-if="checkPermission('view laporan manajemen ' + laporan_manajemen.text)"
              >
                {{ laporan_manajemen.text }}
              </router-link>
            </li>
          </ul>
        </li> -->

        <!-- <li class="has-subMenu" v-if="checkPermission('view permintaan internal')">
          <a href="#" class="parent">
            Permintaan Internal
          </a>
          <ul class="subMenu">
            <li class="has-subMenu-left" v-if="checkPermission('view direksi')">
              <a href="#" class="parent">
                Direksi
              </a>
              <ul class="subMenu">
                <li @click="addParentActive" v-if="checkPermission('view loading mingguan')">
                  <router-link to="/internal/loading-mingguan">
                    Data Loading Mingguan
                  </router-link>
                </li>
                <li @click="addParentActive" v-if="checkPermission('view konfirmasi rakor pltu')">
                  <router-link to="/internal/konfirmasi-rakor-pltu">
                    Data Konfirmasi Rakor PLTU
                  </router-link>
                </li>
                <li @click="addParentActive" v-if="checkPermission('view kumulatif target pasokan')">
                  <router-link to="/internal/kumulatif-target-pasokan">
                    Data Kumulatif Target Pasokan
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="has-subMenu-left" v-if="checkPermission('view dekom')">
              <a href="#" class="parent">
                Dekom
              </a>
              <ul class="subMenu">
                <li @click="addParentActive" v-if="checkPermission('view rekap pasokan mitra')">
                  <router-link to="/internal/rekap-pasokan-mitra">
                    Rekap Pasokan Mitra
                  </router-link>
                </li>
                <li @click="addParentActive" v-if="checkPermission('view realisasi volume pasokan')">
                  <router-link to="/internal/realisasi-volume-pasokan">
                    Realisasi Volume Pasokan
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->

        <!-- <li class="has-subMenu" v-if="checkPermission('view permintaan eksternal')">
          <a href="#" class="parent">
            Permintaan Eksternal
          </a>
          <ul class="subMenu">
            <li @click="addParentActive" v-if="checkPermission('view kepemilikan saham')">
              <router-link to="/eksternal/kepemilikan-saham">
                Data Kepemilikan Saham PLNBB Grup
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view penyertaan modal')">
              <router-link to="/eksternal/penyertaan-modal">
                Daftar Penyertaan Modal Pada Perusahaan Afiliasi
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view notulen')">
              <router-link to="/eksternal/notulen">
                Notulen Rakom, Rawas, RUPS
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view pengawasan dekom')">
              <router-link to="/eksternal/pengawasan-dekom">
                Laporan Tugas Pengawasan Dekom
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view kpi direksi dekom')">
              <router-link to="/eksternal/kpi-direksi-dekom">
                KPI Direksi dan Dekom
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view aktar risalah')">
              <router-link to="/eksternal/akta-risalah">
                Akta dan Risalah RUPS LPT dan RUPS RKAP
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view daftar hadir radir')">
              <router-link to="/eksternal/daftar-hadir-radir">
                Daftar Hadir Radir
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view bisnis model plnbb')">
              <router-link to="/eksternal/bisnis-model-plnbb">
                Bisnis Model PLNBB
              </router-link>
            </li>
            <li @click="addParentActive" v-if="checkPermission('view laporan manajemen eksternal')">
              <router-link to="/eksternal/laporan-manajemen-eksternal">
                Laporan Manajemen
              </router-link>
            </li>
          </ul>
        </li> -->

        <li class="has-subMenu" v-if="checkPermission('view master')">
          <a href="#" class="parent">
            Master
          </a>
          <ul class="subMenu">
            <li class="has-subMenu-left">
              <a href="#" class="parent">
                User
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/user">
                    User
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/role">
                    Role
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/permission">
                    Permission
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="has-subMenu-left">
              <a href="#" class="parent">
                KPI
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/satuan-kpi">
                    Satuan
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/jenis-variable-kpi">
                    Jenis Variable
                  </router-link>
                </li>
                <li @click="addParentActive">
                  <router-link to="/master/direktorat">
                    Direktorat
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/divisi">
                    Divisi
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/kpi-divisi">
                    KPI
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/variable-kpi-divisi">
                    Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-variable-kpi-divisi">
                    Realisasi Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-kpi-divisi">
                    Realisasi
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/target-kpi-divisi">
                    Target
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="has-subMenu-left">
              <a href="#" class="parent">
                Indikator Kinerja
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/kpi-korporat">
                    Indikator Kinerja
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/variable-kpi-korporat">
                    Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-variable-kpi-korporat">
                    Realisasi Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-kpi-korporat">
                    Realisasi
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/target-kpi-korporat">
                    Target
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- <li class="has-subMenu-left">
              <a href="#" class="parent">
                KPI
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/direktorat">
                    Direktorat
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/divisi">
                    Divisi
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/kpi-divisi">
                    KPI
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/variable-kpi-divisi">
                    Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-variable-kpi-divisi">
                    Realisasi Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/realisasi-kpi-divisi">
                    Realisasi
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/target-kpi-divisi">
                    Target
                  </router-link>
                </li>
              </ul>
            </li> -->
            <!-- <li class="has-subMenu-left">
              <a href="#" class="parent">
                Laporan Manajemen
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/laporan-manajemen">
                    Laporan Manajemen
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/jenis-variable-laporan-manajemen">
                    Jenis Variable
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/variable-laporan-manajemen">
                    Variable
                  </router-link>
                </li>
              </ul>
            </li> -->
            <!-- <li class="has-subMenu-left">
              <a href="#" class="parent">
                Permintaan Internal / Eksternal
              </a>
              <ul class="subMenu">
                <li @click="addParentActive">
                  <router-link to="/master/perusahaan">
                    Perusahaan
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/bidang-usaha">
                    Bidang Usaha
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/mitra-pemasok">
                    Mitra Pemasok
                  </router-link>
                </li>

                <li @click="addParentActive">
                  <router-link to="/master/pltu">
                    PLTU
                  </router-link>
                </li>
              </ul>
            </li> -->
          </ul>
        </li>
        <li v-if="checkPermission('view rcs')">
          <a href="https://rcsonlines.com" target="_blank" class="parent">
            RCS
          </a>
        </li>
      </ul>
    </div>
  </TopMenuStyle>
</template>
<script>
import { reactive, onMounted } from 'vue';
import { TopMenuStyle } from './style';
// import { useStore } from 'vuex';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

export default {
  name: 'TopMenu',
  components: {
    TopMenuStyle,
  },
  setup() {
    // const { state, dispatch } = useStore();
    // const crud = computed(() => state.crud.data);
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const menus = reactive({
      laporan_manajemen: [],
      laporan_manajemen_target_realisasi: [],
      divisi: [],
      kpiKorporat: [],
      month: null,
      year: null,
    });

    onMounted(() => {
      menus.month = ('0' + month).slice(-2);
      menus.year = dateObj.getUTCFullYear();

      DataService.get('options-direktorat3/').then(response => {
        menus.divisi = response.data;
      });

      DataService.get('options-laporan-manajemen/').then(response => {
        menus.laporan_manajemen = response.data;
      });

      DataService.get('options-laporan-manajemen-target-realisasi/').then(response => {
        menus.laporan_manajemen_target_realisasi = response.data;
      });

      DataService.get('options-kpi-korporat-with-formula/').then(response => {
        menus.kpiKorporat = response.data;
      });

      const active = document.querySelector('.strikingDash-top-menu a.active');
      const activeDefault = () => {
        const megaMenu = active.closest('.megaMenu-wrapper');
        const hasSubMenuLeft = active.closest('.has-subMenu-left');
        if (!megaMenu) {
          active.closest('ul').previousSibling.classList.add('active');
          if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
        } else {
          active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
        }
      };
      window.addEventListener('load', active && activeDefault);
      // return () => window.removeEventListener('load', activeDefault);
    });

    // const addParentActive = event => {
    //   document.querySelectorAll('.parent').forEach(element => {
    //     element.classList.remove('active');
    //   });

    //   const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    //   const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    //   if (!megaMenu) {
    //     event.currentTarget.closest('ul').previousSibling.classList.add('active');
    //     if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    //   } else {
    //     event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    //   }
    // };

    const addParentActive = event => {
      document.querySelectorAll('.parent').forEach(element => {
        element.classList.remove('active');
      });
      console.log(event.currenTarget);

      const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
      const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
      if (!megaMenu) {
        event.currentTarget.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      if (Array.isArray(permission)) {
        return permission.some(p => permissions.includes(p.toLowerCase()));
      }

      return permissions.includes(permission.toLowerCase());
    };

    return { addParentActive, menus, checkPermission, profile };
  },
};
</script>
